import './Shops.css'
import ModalPage from "../ModalPage";
import sbp from '../img/sbp.png'

const Shops = ({ setModal, modal }) => {
    const closeModal = () => setModal(null)

    const getLinkKeksik = (shop) => {
        window.location.href = shop
    }

    if (modal !== 'shops') return null

    return <ModalPage onClose={closeModal} header="Покупки">
        <div className='Block'>
            <div className="BlockHeader">
              Обменники
            </div>

            {
                false &&
                <div className="ShopItem" onClick={() => getLinkKeksik("https://vk.com/goalshop_fc")}>
                    <div className='ShopsAva'>
                        <img src="https://sun9-15.userapi.com/impg/dnr9payUWINj4Seuwjbf3PWwsQJT8k5OxcFfPw/GJ_WXQ_GRXI.jpg?size=1024x1024&quality=95&sign=41a86e27792004aa573f322be948a798&type=album" alt="ava"/>
                    </div>
                    <div className='ShopsBody'>
                        <p>GoalShop | FC</p>
                        <div className='ShopsBodyInfo'>
                            <img src={sbp} alt="sbp"/>
                            <p>СБП / 20 ₽ - 5 000 ₽</p>
                        </div>
                    </div>
                </div>
            }
            <p className='CreateShop'>
                Если хотите стать обменником напишите <a href="https://хор-енотов.рф">мне</a>.
            </p>
        </div>
    </ModalPage>
}

export default Shops