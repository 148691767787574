import './ModalPage.css'
import { createPortal } from 'react-dom';
import { useRef } from 'react';

export default function ModalPage({ children, onClose, style = {} }) { 
    const modal = useRef()
    const modalContent = useRef()

    const closeAlert = () => {
        modal.current.style.animation ='0.3s closeModalPageBg forwards'
        modalContent.current.style.animation ='0.3s closeModalPagePc forwards'

        setTimeout(() => {
            onClose()
        }, 300)
    }

    return createPortal(
        <div className="ModalPage" onClick={closeAlert} ref={modal}>
            <div 
                className="ModalPageContent" 
                onClick={(e) => e.stopPropagation()} 
                style={style}
                ref={modalContent}
            >
                <div className='ModalPageContentBody'>
                    {children}
                </div>
            </div>
        </div>,
      document.getElementById('modal-root')
  ); 
}