import './Shop.css'
import { useEffect, useState } from "react";
import ModalPage from "../ModalPage";
const KEKSIK = 'https://vk.com/app6887721_-189770943#donate_'

const ShopModal = ({ closeModal, fields, id }) => {
    const [items, setItems] = useState([])
    const [activItem, setActiveItem] = useState(0)

    const getLinkKeksik = () => {
        window.location.href = KEKSIK + items[activItem].price
    }

    useEffect(() => {
        const itms = []
        for (let key in fields) {
            if (typeof(fields[key]) === 'object') {
                fields[key].forEach(item => {
                    if (item.tag) itms.push(item)
                })
                
            }
        }

        setItems(itms)
    }, [fields])

    if (!items?.length) return null

    return <ModalPage onClose={closeModal} header="Покупки">
        <div className='Block'>
            <div className="BlockHeader">
              Выберите донат
            </div>

            <div className="BlockBody">
            {items.map((item, index) => {
                let className = 'ShopItemCheckBox'
                if (activItem === index) className += ` Active`
                return <div className="ShopItem" key={item.tag} onClick={() => {
                    setActiveItem(index)
                }}>
                    <div className={className}></div>
                    <p>{`${item.tag} ≈ ${item.price} ₽`}</p>
                </div>
            })}
            </div>
          
            <form 
              method="POST" 
              action="https://yoomoney.ru/quickpay/confirm.xml">    
                <input type="hidden" name="receiver" value={4100118506084210}/>    
                <input type="hidden" name="formcomment" value="XOP EHOTOB"/>    
                <input type="hidden" name="short-dest" value="XOP EHOTOB"/>    
                <input type="hidden" name="label" value={`12${id}`}/>    
                <input type="hidden" name="quickpay-form" value="donate"/>    
                <input type="hidden" name="targets" value={`ID: ${id}`}/>    
                <input type="hidden" name="sum" value={items[activItem].price} data-type="number"/>    
                <input type="hidden" name="comment" value={`Донат от пользователя - ${id}`}/>     
                {
                    <center>
                        <input 
                            type="submit" 
                            className="DonateButton" 
                            value='Оплатить'
                        />
                    </center>
                }
            </form>

            <input 
                type="submit" 
                className="DonateButtonKeksik" 
                value='Оплатить (Кексик)' 
                onClick={getLinkKeksik}
            />
        </div>
    </ModalPage>
}

export default function Shop ({ modal, setModal, fields, id }) {
    const closeModal = () => {
        setModal(null)
    }
    
    if (modal !== 'shop') return null
    return <ShopModal 
        modal={modal} 
        closeModal={closeModal} 
        fields={fields}
        id={id}
    />
}