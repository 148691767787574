import './App.css';
import { useEffect, useState } from 'react';
import logo from './img/logo.png'
import request from './utils/request'
import Shop from "./panels/Shop"
import Shops from "./panels/Shops"
import exchange from './img/exchange.svg'

function App() {
  const [id, setId] = useState('')
  const [modal, setModal] = useState(null)
  const [fields, setFields] = useState(null)


  const showShops = () => {
    setModal('shops')
  }

  const getProducts = async () => {
    try {
      let req = await request('get_shop_info', 'POST', {id})
      setFields(req.fields);
      
      if (req.error) {
        setFields('Пользователь не найден!')
      } else {
        setModal('shop')
      }

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (window.location.hash.length > 0) {
      setId(window.location.hash.slice(1))
    }
}, [])


  return (
    <div className="App">
      <div className='Menu'>
        <div className="MainMenu">
          <div className='Header'>
            <img src={logo} alt='logo'/>
            <h1>Football Coin</h1>
          </div>

          <div className='Block'>
            <div className="BlockHeader">
              Покупка доната
            </div>

            <div className="BlockBody">
              <p style={{ opacity: 0.7}}>ВВЕДИТЕ ВАШ ID:</p>
              <input 
                tupe="number" 
                placeholder="Ваш игровой ID"
                value={id}
                onChange={(e) => {
                  setId(e.target.value)
                }}
              />
            </div>

          
            <div>
              {typeof(fields) === 'string' ? fields : null}

              <div 
                className="DonateButton" 
                onClick={getProducts}
              >
                Показать продукты
              </div>
            </div>

            <div 
              className="DonateButton" 
              onClick={showShops}
              style={{ background: "linear-gradient(to left, rgb(255, 140, 0) 0%,rgb(245, 66, 90) 100%)"}}
            >
              <div className='DonateButtonImg'>
                <img src={exchange} alt='icon'/>
              </div>
              <div>Обменники</div>
            </div>
          </div>

          <div className='Footer'>
            <a 
              href='https://footballcoin.ru/agreement.html' 
              target='_blank' 
              rel="noreferrer"
            >
              Соглашение
            </a>

            <a 
              href='https://footballcoin.ru/privacy.html' 
              target='_blank' 
              rel="noreferrer"
            >
              Конфиденциальность
            </a>

          </div>

        </div>
      </div>

      <Shop 
        modal={modal} 
        setModal={setModal}
        fields={fields}
        id={id}
      />

      <Shops
        modal={modal} 
        setModal={setModal}
      />
    </div>
  );
}

export default App;
